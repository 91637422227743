body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tableTextLeft {
  margin: 2em;
  position: relative;
  padding-left: 3.4em;
  text-align: start;
  font-weight: 600;
  padding-bottom: 1em;
}

.tableTextLeft::before {
  content: url('../src/assets/dashboard/voxguru-list-2.svg');
  position: absolute;
  top: 1.3em;
  left: 25px;
}

.tableTextRight {
  margin: 2em;
  position: relative;
  padding-left: 3.4em;
  text-align: start;
  font-weight: 600;
  padding-top: 1em;
  padding-bottom: 1em;
}

.tableTextRight::before {
  content: url('../src/assets/dashboard/voxguru-list-1.svg');
  position: absolute;
  top: 1.3em;
  left: 25px;
}

.remove-scroll::-webkit-scrollbar {
  display: none;
}

/* ------------ Blog ------------ */
.blog-cms-body{
  margin: 24px 0px;
}
.blog-cms-body .blogquote {
  color: #E87663 !important;
  font-size: 38px !important;
  font-weight: 700 !important;
  margin: 50px 0px !important;
  padding: 0px 80px !important;
  position: relative !important;
}
.blog-cms-body .blogquote p {
  color: #E87663 !important;
  font-size: 38px !important;
  font-weight: 700 !important;
}

.blog-cms-body .content-img-container p {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #404040;
  margin: 20px 0px 0px 0px;
}
.blog-cms-body .content-img-container img {
  width: 100%;
  max-width: 754px;
  height: 100%;
  max-height: 424px;
}

.blog-cms-body h2 {
  font-size: 38px;
  color: #E87663;
  font-weight: 700;
  margin: 20px 0px 10px 0px;
}


.blog-cms-body p{
  font-size: 19px;
  margin: 10px 0px;
}

.blog-cms-body .blog-quote-img {
  position: absolute;
  top: 2px;
  left: 0px;
}

.blog-cms-body ul{
  padding-left: 30px;
  line-height: 28px;
}

/* ------------ Blog for mobile ------------ */
@media (max-width: 750px) {

  .blog-cms-body{
    margin: 0px 0px;
  }
  .blog-cms-body .blogquote {
    color: #E87663 !important;
    font-weight: 700 !important;
    font-size: 32px !important;
    padding: 0px 66px !important;
  }

  .blog-cms-body .blogquote p {
    color: #E87663 !important;
    font-weight: 700 !important;
    font-size: 32px !important;
  }

  .blog-cms-body .blog-quote-img {
    width: 39px;
    height: 31px;
  }

  .blog-cms-body h2 {
    font-size: 32px;;
  }

  .blog-cms-body p{
    font-size: 16px;

  }
}

/* ------------ hover effects in indivdual course detail page bundle section ------------ */
@media (max-width: 995px) {
  .pointer {
    position: relative;
  }

  .pointer::before {
    content: url('./assets/dashboard/polygon.svg');
    width: 20px;
    height: 15px;
    position: absolute;
    left: -25px;
  }
}